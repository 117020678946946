<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col lg="7" md="10">
          <b-card no-body class="mx-4">
            <b-card-body class="p-4">
              <b-form>
                <h1>Inscription</h1>
                
                <form-wizard @on-complete="" ref="wizard" step-size="xs" color="#20A8D8" errorColor="#f86c6b">
                  <template slot="title">
                    <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                    <p class="text-muted" v-else>Inscrivez-vous pour pouvoir gérer une ferme</p>
                  </template>
                  
                  <tab-content title="Infos personnelles" icon="icon-user"
                               :beforeChange="beforeSkipPersonalData">
                    
                    <c-input container-class="mb-3 required-input" label="Votre nom" placeholder="Ex: KRATOS"
                             v-model="lastName" :state="lastNameState">
                      Veuillez saisir un nom
                    </c-input>
                    
                    <c-input container-class="mb-3 required-input" label="Votre prénom" placeholder="Ex: Bob"
                             v-model="firstName" :state="firstNameState">
                      Veuillez saisir un prénom
                    </c-input>
                    
                    <c-input container-class="mb-3 required-input" label="Votre adresse email" placeholder="Ex: bob@gmail.com"
                             v-model="email" :state="emailState">
                      Veuillez saisir une adresse email valide
                    </c-input>
                    
                    <c-input container-class="mb-3 required-input" type="tel"
                             label="Votre numéro de téléphone" placeholder="Ex: 680125630"
                             v-model="phoneNumber" :state="phoneNumberState">
                      Téléphone invalide
                    </c-input>
                  </tab-content>
                  
                  <tab-content title="Validation du numéro de téléphone" icon="icon-phone"
                               :beforeChange="beforeSkipPhoneConfirm">
                    <b-form-text class="mb-3">
                      Un code de validation a été envoyé par SMS au numéro de téléphone {{phoneNumber}}.
                      Veuillez entrer ce code pour continuer.
                    </b-form-text>
                    <b-form-text class="mb-4">
                      Vous n'avez pas reçu de SMS ?
                      <b-link block variant="primary" type="button" @click="beforeSkipPersonalData">Cliquez ici</b-link>
                      pour renvoyer
                    </b-form-text>
                    
                    <c-input container-class="mb-3 required-input" label="Code de vérification" placeholder="Ex: 123456"
                             v-model="phoneConfirm" :state="phoneConfirmState">
                      Code de confirmation invalide
                    </c-input>
                  </tab-content>
                  
                  <tab-content title="Sécurité" icon="icon-lock">
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select v-model="question1" :options="questionOptions[0]" :state="question1State"
                                     aria-describedby="question1-feedback">
                        <template slot="first">
                          <option :value=null disabled>-- Question secrète 1 --</option>
                        </template>
                      </b-form-select>
                      <b-form-input type="text" class="form-control" placeholder="Réponse"
                                    v-model="answer1" :state="question1State" />
                      <b-form-invalid-feedback id="question1-feedback">
                        Sélectionnez une question et entrez une réponse
                      </b-form-invalid-feedback>
                    </b-input-group>
                    
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select v-model="question2" :options="questionOptions[1]" :state="question2State"
                                     aria-describedby="question2-feedback">
                        <template slot="first">
                          <option :value=null disabled>-- Question secrète 2 --</option>
                        </template>
                      </b-form-select>
                      <b-form-input type="text" class="form-control" placeholder="Réponse"
                                    v-model="answer2" :state="question2State" />
                      <b-form-invalid-feedback id="question2-feedback">
                        Sélectionnez une question et entrez une réponse
                      </b-form-invalid-feedback>
                    </b-input-group>
                    
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select v-model="question3" :options="questionOptions[2]" :state="question3State"
                                     aria-describedby="question1-feedback">
                        <template slot="first">
                          <option :value=null disabled>-- Question secrète 3 --</option>
                        </template>
                      </b-form-select>
                      <b-form-input type="text" class="form-control" placeholder="Réponse"
                                    v-model="answer3" :state="question3State" />
                      <b-form-invalid-feedback id="question3-feedback">
                        Sélectionnez une question et entrez une réponse
                      </b-form-invalid-feedback>
                    </b-input-group>
                    
                    <c-input container-class="mb-3 required-input" type="password"
                             label="Votre mot de passe" placeholder="Entrez un mot de passe"
                             v-model="password" :state="passwordState">
                      Votre mot de passe doit avoir au moins 8 caractères, contenir au moins un chiffre, au moins un
                      caractère spécial et au moins un caractère alphabétique
                    </c-input>
                    <c-input container-class="mb-3" type="password"
                             label="Confirmation du mot de passe" placeholder="Entrez à nouveau le mot de passe"
                             v-model="passwordConfirm" :state="passwordConfirmState">
                      Les mots de passe ne correspondent pas
                    </c-input>
                    
                    <b-input-group class="mb-4 ml-1">
                      <b-form-checkbox class="text-muted cursor-pointer" v-model="accept" :state="acceptState">
                        J'accepte les
                        <b-link href="/login" target="_blank">
                          conditions d'utilisation <i class="fa fa-external-link"></i>
                        </b-link>
                        de PIA
                      </b-form-checkbox>
                      <b-form-invalid-feedback :state="acceptState">
                        Acceptez les conditions d'utilisation pour continuer
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </tab-content>
                 
                  <template slot="footer" slot-scope="props">
                    <div class="wizard-footer-left">
                      <span></span>
                    </div>
                    <div class="wizard-footer-right">
                      <b-button v-if="!props.isLastStep" @click="props.nextTab()"
                                     variant="primary">
                        <b-spinner small class="mr-2" v-if="fetching"></b-spinner>
                        Suivant
                      </b-button>
                      
                      <b-button v-else @click="register" variant="success">
  
                        <b-spinner small class="mr-2" v-if="fetching"></b-spinner>
                        {{props.isLastStep ? 'Créer mon compte' : 'Next'}}
                      </b-button>
                    </div>
                  </template>
                </form-wizard>
                
                <b-modal title="Inscription réussie" class="modal-success" v-model="successModal"
                         @ok="closeModal" ok-only ok-variant="success">
                  Un lien d'activation de votre compte a été envoyé à l'adresse
                  <b>{{email}}</b>
                  Cliquez sur ce lien pour activer votre compte votre compte.
                </b-modal>
              </b-form>
            </b-card-body>
            <b-card-footer class="p-4">
              <b-row>
                <b-col cols="6">
                  Vous disposez déjà d'un compte ?
                </b-col>
                <b-col cols="6">
                  <b-link block variant="primary" type="button" to="/login"><span>Connectez-vous</span></b-link>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Regex, Api, Toast} from "../../helpers";
  import {FormWizard, TabContent} from 'vue-form-wizard'
  
  export default {
    name: 'Register',
    title: 'PIA - Inscription',
    components: {
      FormWizard, TabContent},
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phoneConfirm: '',
        confirmedPhone: null,
        
        questionList: [],
        question1: null,
        question2: null,
        question3: null,
        answer1: '',
        answer2: '',
        answer3: '',
        
        password: '',
        passwordConfirm: '',
        accept: false,
        
        successModal: false,
        
        submitted: false,
        submittedPersonalData: false,
        submittedPhoneConfirm: false,
        
        error: null,
        fetching: false
      }
    },
    created() {
      // this.fetchingQuestionList = true
      Api.get('/auth/question/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.questionList = res.data.data.map(question => ({
              value: question.id,
              text: question.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          // this.fetchingQuestionList = false
        })
      
      const emailParam = this.$route.query.email
      if (emailParam)
        this.email = emailParam
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      firstNameState() {
        return !this.submittedPersonalData || this.firstName.trim().length >= 3 ? null : false
      },
      lastNameState() {
        return !this.submittedPersonalData || this.lastName.trim().length >= 3 ? null : false
      },
      emailState() {
        return !this.submittedPersonalData || Regex.isEmail(this.email) ? null : false
      },
      phoneNumberState() {
        return !this.submittedPersonalData || Regex.isPhoneNumber(this.phoneNumber) ? null : false
      },
      
      phoneConfirmState() {
        return !this.submittedPhoneConfirm || Regex.isValidPhoneConfirmationCode(this.phoneConfirm) ? null : false
      },
      
      questionOptions() {
        return [
          this.questionList.filter(question => question.value !== this.question2 && question.value !== this.question3),
          this.questionList.filter(question => question.value !== this.question1 && question.value !== this.question3),
          this.questionList.filter(question => question.value !== this.question1 && question.value !== this.question2),
        ]
      },
      questions() {
        return [
          {
            questionId: this.question1,
            answer: this.answer1
          },
          {
            questionId: this.question2,
            answer: this.answer2
          },
          {
            questionId: this.question3,
            answer: this.answer3
          }
        ]
      },
      question1State() {
        return !this.submitted || (this.question1 != null && this.answer1.trim().length >= 3) ? null : false
      },
      question2State() {
        return !this.submitted || (this.question2 != null && this.answer2.trim().length >= 3) ? null : false
      },
      question3State() {
        return !this.submitted || (this.question3 != null && this.answer3.trim().length >= 3) ? null : false
      },
      passwordState() {
        return !this.submitted || Regex.isPassword(this.password) ? null : false
      },
      passwordConfirmState() {
        return !this.submitted || this.passwordConfirm === this.password ? null : false
      },
      acceptState() {
        return !this.submitted || this.accept ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      isLastStep() {
        if (this.$refs.wizard) {
      
          return this.$refs.wizard.isLastStep
        }
        return false
      },
      closeModal() {
        this.successModal = false
        this.$router.push('/login')
      },
      onPhoneChange({number, isValid, country}) {
        if (number !== this.confirmedPhone)
          this.confirmedPhone = null
      },
      validPersonalData() {
        return this.firstName.trim().length >= 3
          && this.lastName.trim().length >= 3
          && Regex.isEmail(this.email)
          && Regex.isPhoneNumber(this.phoneNumber)
      },
      async beforeSkipPersonalData() {
        this.submittedPersonalData = true
        if (!this.validPersonalData())
          return false
        if (this.confirmedPhone) {
          this.error = ""
          return true
        }
  
        this.fetching = true
        const res = await Api.post('/auth/send-sms', {
          email: this.email,
          phoneNumber: this.phoneNumber
        })
  
        if (res.data.status === 'success' && res.data.data) {
          this.fetching = false
          this.error = ""
          return true
        }else {
          this.error = res.data.error
          this.fetching = false
          return false
        }
      },
      validPhoneConfirm() {
        return Regex.isValidPhoneConfirmationCode(this.phoneConfirm)
      },
      async beforeSkipPhoneConfirm() {
        this.submittedPhoneConfirm = true
        if (!this.validPhoneConfirm())
          return false
        if (this.confirmedPhone) {
  
          this.error = ""
          return true
        }
  
  
        this.fetching = true
        const res = await Api.post('/auth/confirm-phone', {
          phoneNumber: this.phoneNumber,
          code: this.phoneConfirm
        })
  
        if (res.data.status === 'success' && res.data.data) {
          this.confirmedPhone = this.phoneNumber
          this.fetching = false
          this.error = ""
          return true
        }else {
          this.error = res.data.error
          this.fetching = false
          return false
        }
      },
      valid() {
        return (this.question1 != null && this.answer1.trim().length >= 3)
          && (this.question2 != null && this.answer2.trim().length >= 3)
          && (this.question3 != null && this.answer3.trim().length >= 3)
          && Regex.isPassword(this.password)
          && this.passwordConfirm === this.password
          && this.accept
      },
      register() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetching = true
        Api.post('/register', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          password: this.password,
          questions: this.questions
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.successModal = true
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetching = false
          })
      }
    }
  }
</script>

<style scoped>
  @import "~vue-form-wizard/dist/vue-form-wizard.min.css";
  @import "~vue-tel-input/dist/vue-tel-input.css";
</style>

<style>
  .vue-form-wizard{
    padding-bottom: 0 !important;
  }
  
  .vue-form-wizard .wizard-navigation{
    min-height: 330px !important;
  }
  
  .vue-form-wizard .wizard-header{
    padding: 0 !important;
    text-align: left !important;
  }
  
  .vue-form-wizard .wizard-card-footer{
    margin-top: auto !important;
  }
  
  .tel-input-container{
    width: 100%;
    border-color: #e4e7ea !important;
  }
  
  .tel-input-container .dropdown{
    background-color: #f0f3f5;
  }
  
  .tel-input-container .tel-input{
    border-left: 1px solid #e4e7ea !important;
    padding: 0.375rem 0.75rem !important;
  }
  
  .left-icon{
    padding: 0 20px;
  }

</style>
